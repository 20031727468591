module.exports = {
  locales: [`en-US`, `en-CA`, `en-GB`, `de-DE`, `es-MX`],
  defaultLocale: `en-US`,
  redirects: [
    { fromPath: '/us', toPath: '/en-US' },
    { fromPath: '/gb', toPath: '/en-GB' },
    { fromPath: '/de', toPath: '/de-DE' },
    { fromPath: '/ca', toPath: '/en-CA' },
    { fromPath: '/mx', toPath: '/es-MX' },
  ],
  siteConfigs: {
    'en-US': {
      timezone: 'America/New_York',
      sweepsStart: '2019-09-01 00:00:01',
      sweepsEnd: '2019-11-30 11:59:59',
      rewardsStart: '2019-09-01 00:00:01',
      rewardsEnd: '2020-03-30 23:59:59',
      formats: {
        shortDate: 'MM/DD',
        shortTime: 'h:mma',
      },
    },
    'en-CA': {
      timezone: 'America/New_York',
      sweepsStart: '2019-09-01 00:00:01',
      sweepsEnd: '2019-11-30 11:59:59',
      rewardsStart: '2019-09-01 00:00:01',
      rewardsEnd: '2020-03-30 23:59:59',
      formats: {
        shortDate: 'MM/DD',
        shortTime: 'h:mma',
      },
    },
    'de-DE': {
      timezone: 'Europe/Berlin',
      sweepsStart: '2019-09-01 00:00:01',
      sweepsEnd: '2019-11-30 23:59:59',
      rewardsStart: '2019-09-01 00:00:01',
      rewardsEnd: '2020-03-30 23:59:59',
      formats: {
        shortDate: 'DD/MM',
        shortTime: 'h:mma',
      },
    },
    'en-GB': {
      timezone: 'Europe/London',
      sweepsStart: '2019-09-01 00:00:01',
      sweepsEnd: '2019-11-29 23:59:59',
      rewardsStart: '2019-09-01 00:00:01',
      rewardsEnd: '2020-03-30 23:59:59',
      formats: {
        shortDate: 'DD/MM',
        shortTime: 'h:mma',
      },
    },
    'es-MX': {
      timezone: 'America/Mexico_City',
      sweepsStart: '2019-09-01 00:00:01',
      sweepsEnd: '2019-11-30 23:59:59',
      rewardsStart: '2019-09-01 00:00:01',
      rewardsEnd: '2020-03-30 23:59:59',
      formats: {
        shortDate: 'DD/MM',
        shortTime: 'h:mma',
      },
    },
  },
};
