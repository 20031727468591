import styled from 'styled-components';
import * as defaultTheme from '../variables';

import HeroMyStuffBg from '../../images/heromystuff-bg.jpg';

const HeroMyStuffStyled = styled.section`
  background-image: url(${HeroMyStuffBg});
  background-repeat: no-repeat;
  background-size: cover;

  @media (${p => p.theme.xsMax}) {
    margin-top: ${p => p.theme.space * 5}px;
    padding-bottom: ${p => p.theme.space * 5}px;
    background-position: center;
  }

  @media (${p => p.theme.smMin}) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 495px;
    margin-top: ${p => p.theme.space * 8}px;
    padding-bottom: ${p => p.theme.space * 3}px;
    background-position: 60% 40%;
  }

  @media (${p => p.theme.lgMin}) {
    height: 545px;
    background-position: center top;
  }

  .HeroMyStuff__inner {
    width: 100%;
    max-width: ${p => p.theme.maxWidth}px;
    margin: 0 auto;
    padding: 0 ${p => p.theme.space * 3}px;

    @media (${p => p.theme.mdMin}) {
      display: flex;
      justify-content: space-between;
    }
  }

  .HeroMyStuff__title {
    margin: 0;
    color: ${p => p.theme.red};
    font-family: 'MrBlack';
    font-size: 56px;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-shadow: 0 0 10px ${p => p.theme.textColorBlack};
    -webkit-font-smoothing: initial;

    @media (${p => p.theme.xsMax}) {
      padding-top: 20%;
    }

    @media (${p => p.theme.lgMin}) {
      font-size: 64px;
      letter-spacing: 1.14px;
    }

    p {
      margin: 0;
      color: ${p => p.theme.white};
      font-size: 34px;

      @media (${p => p.theme.lgMin}) {
        font-size: 40px;
      }
    }
  }

  .HeroMyStuff__subtitle {
    max-width: 350px;
    color: ${p => p.theme.textColorWhite};

    @media (${p => p.theme.xsMax}) {
      padding: ${p => p.theme.space}px 0 ${p => p.theme.space * 2}px;
    }

    @media (${p => p.theme.smMin}) {
      margin-top: ${p => p.theme.space}px;
      margin-bottom: ${p => p.theme.space * 3}px;
    }
  }

  .HeroMyStuff__packs {
    margin-top: ${p => p.theme.space * 3}px;
    color: ${p => p.theme.textColorWhite};

    @media (${p => p.theme.mdMin}) {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      min-width: 560px;
      margin-top: 0;
    }
  }

  .HeroMyStuff__packTitle {
    margin-bottom: ${p => p.theme.space}px;

    p {
      display: inline-block;
      margin: 0;
    }
  }
`;

HeroMyStuffStyled.defaultProps = {
  theme: defaultTheme,
};

export default HeroMyStuffStyled;
