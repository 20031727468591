import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { FormattedMessage, defineMessages } from 'react-intl';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';

import MyStuffStyled from './MyStuff.style';

import { prizes } from './config';
import data from '../Rewards/config';

import Heading from '../Heading/Heading';
import MyStuffItem from '../MyStuffItem/MyStuffItem';
import MyStuffPrize from '../MyStuffPrize/MyStuffPrize';
import { siteConfigs } from '../../config';

const messages = defineMessages({
  title1: {
    id: 'myStuff.title1',
    defaultMessage: 'REWARD',
    description: 'Title 1',
  },
  title2: {
    id: 'myStuff.title2',
    defaultMessage: 'REDEMPTION',
    description: 'Title 2',
  },
  subtitle: {
    id: 'myStuff.subtitle',
    defaultMessage:
      'Click the “Redeem” button on each item below to activate your rewards.',
    description: 'Subtitle',
  },
  loadMore: {
    id: 'myStuff.loadMore',
    defaultMessage: 'Load More',
    description: 'Load More',
  },
  asideTitle1: {
    id: 'myStuff.asideTitle1',
    defaultMessage: 'PRIZE ENTRY',
    description: 'Aside Title 1',
  },
  noRedemptions: {
    id: 'myStuff.noRedemptions',
    defaultMessage:
      "You don't have any redemptions yet. Come back once you've redeemed codes to track rewards you've unlocked.",
    description: 'noRedemptions',
  },
  asideTitle2: {
    id: 'myStuff.asideTitle2',
    defaultMessage: 'TRACKERS',
    description: 'Aside Title 2',
  },
  asideDesc: {
    id: 'myStuff.asideDesc',
    defaultMessage:
      'Keep track of your daily and grand prize entries. Good luck!',
    description: 'Aside Desc',
  },
  sweepsEnds: {
    id: 'myStuff.sweepsEnds',
    defaultMessage: 'The sweeps ends 11/30/19.',
    description: 'Sweeps ends',
  },
  entriesReset: {
    id: 'myStuff.entriesReset',
    defaultMessage: 'ENTRIES RESET IN',
    description: 'Entries Reset',
  },
});

class MyStuff extends React.Component {
  render() {
    const {
      intl,
      // daily,
      grandUSCA,
      grandDE,
      redemptions,
      isXboxLinked,
    } = this.props;
    const siteConfig =
      siteConfigs[intl.locale] || siteConfigs[Object.keys(siteConfigs)[0]];
    const siteFormats = siteConfig.formats;

    const prizeEntryTrackers = prizes(
      intl,
      (redemptions || []).length, // daily,
      grandUSCA,
      grandDE,
    ).map((tracker, index) => (
      <MyStuffPrize
        data={tracker}
        key={index.toString()}
        style={{ animationDelay: `${(index + 1) * 150}ms` }}
      />
    ));
    const redemptionsEnhanced = [];
    redemptions.forEach(redemption => {
      // find pack that corresponds
      let packConfig = data.packs.find(itm => itm.id === redemption.pack);
      if (packConfig == null) {
        packConfig = data.chainExclusives.find(
          itm => itm.id === redemption.pack,
        );
      }
      packConfig.tokenTypes.forEach(tokenType => {
        const tokenObj = (redemption.packTokens || []).find(
          tkn => tkn.type === tokenType.id,
        );
        redemptionsEnhanced.push({
          dateRedeemed: redemption.dateRedeemed,
          redemptionId: redemption._id,
          tokenType: tokenType.id,
          token: tokenObj ? tokenObj.token : null,
          date: moment(redemption.dateRedeemed).format(siteFormats.shortDate),
          time: moment(redemption.dateRedeemed).format(siteFormats.shortTime),
          slides: packConfig.slides.filter(
            sld => sld.tokenType === tokenType.id,
          ),
          title: tokenType.title,
          desc: tokenType.desc,
        });
      });
    });
    const now = moment.tz(siteConfig.timezone);
    const isPreRedeem = now.isBefore(
      moment.tz('2019-09-02', siteConfig.timezone),
    );
    redemptionsEnhanced.sort((a, b) => a.dateRedeemed - b.dateRedeemed);
    const rewardRedemptions = redemptionsEnhanced.map((reward, index) => (
      <MyStuffItem
        data={reward}
        isPreRedeem={isPreRedeem}
        key={index.toString()}
        style={{ animationDelay: `${(index + 1) * 150}ms` }}
        isXboxLinked={isXboxLinked}
      />
    ));

    return (
      <MyStuffStyled>
        <div className="MyStuff__inner">
          <div className="MyStuff__main">
            <div className="MyStuff__header">
              <Heading className="MyStuff__heading" as="h3" white>
                <FormattedMessage {...messages.title1} />
                <FormattedMessage {...messages.title2} />
              </Heading>

              <div className="MyStuff__subtitle">
                <FormattedMessage {...messages.subtitle} />
              </div>
            </div>

            <div className="MyStuff__rewards">
              {rewardRedemptions.length > 0 ? (
                rewardRedemptions
              ) : (
                <p>
                  <FormattedMessage {...messages.noRedemptions} />
                </p>
              )}
            </div>
          </div>

          <div className="MyStuff__aside">
            <div className="MyStuff__header --aside">
              <Heading className="MyStuff__heading" as="h4" white>
                <FormattedMessage {...messages.asideTitle1} />
                <FormattedMessage {...messages.asideTitle2} />
              </Heading>

              <div className="MyStuff__subtitle">
                <FormattedMessage {...messages.asideDesc} />{' '}
                <FormattedMessage {...messages.sweepsEnds} />
              </div>
            </div>

            <h4 className="MyStuff__asideTitle">
              <FormattedMessage {...messages.asideTitle1} />{' '}
              <FormattedMessage {...messages.asideTitle2} />
            </h4>
            <div className="MyStuff__asideDesc">
              <FormattedMessage {...messages.asideDesc} />{' '}
              <FormattedMessage {...messages.sweepsEnds} />
            </div>

            <div className="MyStuff__prizes">{prizeEntryTrackers}</div>
          </div>
        </div>
      </MyStuffStyled>
    );
  }
}

MyStuff.propTypes = {
  redemptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  intl: intlShape.isRequired,
  daily: PropTypes.number.isRequired,
  grandUSCA: PropTypes.number.isRequired,
  grandDE: PropTypes.number.isRequired,
  isXboxLinked: PropTypes.bool.isRequired,
};

const InjectedIntlComponent = injectIntl(MyStuff);

export default InjectedIntlComponent;
