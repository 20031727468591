import styled from 'styled-components';
import * as defaultTheme from '../variables';
import { fadeIn } from '../animations';

const MyStuffItemStyled = styled.div`
  animation-name: ${fadeIn};
  animation-duration: 800ms;
  animation-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  animation-fill-mode: forwards;
  opacity: 0;

  &:not(:last-child) {
    margin-bottom: ${p => p.theme.space * 8}px;
  }

  .MyStuffItem__inside {
    padding: ${p => p.theme.space * 3}px;
    color: ${p => p.theme.textColorWhite};
    background-color: rgba(255, 255, 255, 0.2);

    @media (${p => p.theme.smMin}) {
      display: flex;
    }
  }

  .MyStuffItem__date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'AzbukaPro';
    font-size: 28px;
    font-weight: 900;
    line-height: 1.3;

    @media (${p => p.theme.xsMax}) {
      float: right;
    }

    @media (${p => p.theme.smMin}) {
      padding-right: ${p => p.theme.space * 2}px;
    }
  }

  .MyStuffItem__dateTime {
    font-size: 19px;
    font-weight: 500;
  }

  .MyStuffItem__slider {
    margin-top: -${p => p.theme.space * 6}px;

    @media (${p => p.theme.xsMax}) {
      width: 130px;
      margin-bottom: ${p => p.theme.space * 2}px;
    }

    @media (${p => p.theme.smMin}) {
      width: 170px;
      margin-bottom: -${p => p.theme.space * 6}px;
    }
  }

  .MyStuffItem__img {
    flex-shrink: 0;
    position: relative;
    margin-top: -${p => p.theme.space * 6}px;

    @media (${p => p.theme.xsMax}) {
      width: 130px;
      margin-bottom: ${p => p.theme.space * 2}px;
    }

    @media (${p => p.theme.smMin}) {
      width: 170px;
      margin-bottom: -${p => p.theme.space * 6}px;
    }
  }

  .MyStuffItem__imgTitle {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    top: 95px;
    width: 100%;
    height: 50px;
    padding: 0 20px;
    font-family: 'AzbukaPro';
    font-size: 11px;
    font-weight: 500;
    text-align: center;
    line-height: 12px;
    text-transform: uppercase;

    @media (${p => p.theme.smMin}) {
      top: 140px;
      padding: 0 ${p => p.theme.space * 4}px;
      font-size: 12px;
      line-height: 14px;
    }
  }

  .MyStuffItem__code {
    @media (${p => p.theme.smMin}) {
      padding-left: ${p => p.theme.space * 2}px;
    }
  }

  .MyStuffItem__codeTitle {
    font-family: 'AzbukaPro';
    font-size: 18px;
    font-weight: 900;
    line-height: 24px;
    text-transform: uppercase;

    @media (${p => p.theme.mdMin}) {
      font-size: 22px;
    }

    p {
      display: inline-block;
      margin: 0;
    }
  }

  .MyStuffItem__codeDesc {
    margin-top: 8px;
    line-height: 1.3;
  }

  .MyStuffItem__link {
    color: ${p => p.theme.white};
    text-decoration: underline;
    transition: color ${p => p.theme.transitionFast};

    /* &:hover {
      color: ${p => p.theme.darkBlue};
    } */
  }

  .MyStuffItem__field {
    display: flex;
    max-width: 430px;
    margin-top: ${p => p.theme.space * 2}px;
  }

  .MyStuffItem__input {
    width: 100%;
    height: 36px;
    padding: 0 ${p => p.theme.space}px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 3px;

    &::placeholder {
      color: #e5e5e5;
    }
  }

  .MyStuffItem__btn {
    flex-shrink: 0;
    width: 125px;
    height: 36px;
    color: ${p => p.theme.textColorBlack};
    background-color: ${p => p.theme.green};
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 0.47px;
    text-transform: uppercase;
  }
`;

MyStuffItemStyled.defaultProps = {
  theme: defaultTheme,
};

export default MyStuffItemStyled;
