import styled from 'styled-components';

const HeroMyStuffSliderStyled = styled.div`
  .HeroMyStuffSlider__title {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    bottom: 18px;
    width: 100%;
    padding: 0 13px;
    font-family: 'AzbukaPro';
    font-size: 9px;
    font-weight: 500;
    text-align: center;
    line-height: 11px;
  }
`;

export default HeroMyStuffSliderStyled;
