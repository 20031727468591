import styled from 'styled-components';
import * as defaultTheme from '../variables';

import MyStuffBg from '../../images/mystuff-bg.jpg';

const MyStuffStyled = styled.section`
  padding: ${p => p.theme.space * 6}px 0;
  color: ${p => p.theme.textColorWhite};
  background-image: url(${MyStuffBg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .MyStuff__inner {
    display: flex;
    flex-direction: column;
    max-width: ${p => p.theme.maxWidth}px;
    margin: 0 auto;

    @media (${p => p.theme.smMin}) {
      padding: 0 ${p => p.theme.space * 2}px;
    }

    @media (${p => p.theme.lgMin}) {
      flex-direction: row;
    }
  }

  .MyStuff__header {
    @media (${p => p.theme.smMin}) {
      display: flex;
      align-items: center;
    }

    @media (${p => p.theme.lgMin}) {
      align-items: flex-end;

      &.--aside {
        display: none;
      }
    }
  }

  .MyStuff__heading {
    flex-shrink: 0;
  }

  .MyStuff__subtitle {
    padding: 20px;
    line-height: 14px;

    @media (${p => p.theme.smMin}) {
      max-width: 430px;
      margin-left: ${p => p.theme.space * 7}px;
      padding: 0;
      line-height: 24px;
    }
  }

  .MyStuff__rewards {
    height: 700px;
    padding-top: ${p => p.theme.space * 3}px;
    padding-bottom: ${p => p.theme.space * 3}px;
    padding-right: ${p => p.theme.space * 2}px;
    overflow-y: auto;

    @media (${p => p.theme.xsMax}) {
      margin-top: ${p => p.theme.space * 4}px;
      padding: ${p => p.theme.space * 3}px;
    }

    @media (${p => p.theme.smMin}) {
      margin-top: ${p => p.theme.space * 8}px;
    }

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: none;
      border: 0 solid transparent;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.2);
      border: 0 solid transparent;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  .MyStuff__load {
    margin-top: ${p => p.theme.space * 3}px;
    margin-bottom: ${p => p.theme.space * 2}px;
    text-align: center;

    @media (${p => p.theme.lgMin}) {
      margin-top: ${p => p.theme.space * 6}px;
    }
  }

  .MyStuff__loadBtn {
    width: 100%;
    max-width: 351px;
    height: 48px;
    color: ${p => p.theme.textColorBlack};
    background-color: ${p => p.theme.secondary};
    font-size: 18px;
    font-weight: 900;
    letter-spacing: 0.91px;
    text-transform: uppercase;

    @media (${p => p.theme.lgMin}) {
      width: 351px;
      height: 56px;
      font-size: 31px;
    }
  }

  .MyStuff__aside {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media (${p => p.theme.mdMax}) {
      order: -1;
      align-items: flex-start;
      margin-bottom: ${p => p.theme.space * 6}px;
    }

    @media (${p => p.theme.lgMin}) {
      padding-left: ${p => p.theme.space * 4}px;
    }
  }

  .MyStuff__asideTitle {
    margin: 0 0 ${p => p.theme.space * 2}px;
    font-family: 'AzbukaPro';
    font-size: 24px;
    font-weight: 900;
    line-height: 1;
    text-transform: uppercase;

    @media (${p => p.theme.smMin}) {
      font-size: 28px;
    }

    @media (${p => p.theme.lgMin}) {
      font-size: 31px;
    }
  }

  .MyStuff__asideTitle,
  .MyStuff__asideDesc {
    @media (${p => p.theme.mdMax}) {
      display: none;
    }
  }

  .MyStuff__prizes {
    @media (${p => p.theme.xsMax}) {
      padding: 0 ${p => p.theme.space * 3}px;
    }

    @media (${p => p.theme.smMin}) {
      margin-top: ${p => p.theme.space * 2}px;
    }
  }
`;

MyStuffStyled.defaultProps = {
  theme: defaultTheme,
};

export default MyStuffStyled;
