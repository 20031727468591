import React from 'react';
import moment from 'moment-timezone';
import { FormattedMessage, defineMessages } from 'react-intl';
import { siteConfigs } from '../../config';
import imgPeriod1 from '../../images/prize-xbox-min.png';
import imgPeriod2 from '../../images/prize-game-min.png';
import imgRaptor2 from '../../images/5 - Raptor 2.jpg';
import imgRanger1 from '../../images/Ranger 1.jpg';

const messages = defineMessages({
  periodDaily: {
    id: 'myStuff.periodDaily',
    defaultMessage: 'Daily',
    description: 'Daily',
  },
  periodGrand: {
    id: 'myStuff.periodGrand',
    defaultMessage: 'Grand',
    description: 'Grand',
  },
  prize1: {
    id: 'myStuff.prize1',
    defaultMessage: 'XBOX CONSOLE',
    description: 'Prize 1',
  },
  prize2: {
    id: 'myStuff.prize2',
    defaultMessage: 'GEARS 5 GAMES',
    description: 'Prize 2',
  },
  prize3: {
    id: 'myStuff.prize3',
    defaultMessage: 'FORD RAPTOR',
    description: 'Prize 3',
  },
});

// eslint-disable-next-line import/prefer-default-export
export const prizes = (intl, daily, grandUSCA, grandDE, loading) => {
  const siteConfig =
    siteConfigs[intl.locale] || siteConfigs[Object.keys(siteConfigs)[0]];
  const { timezone, sweepsEnd, sweepsStart } = siteConfig;
  const baseExpiresConfig = {
    timezone,
    expires: sweepsEnd,
  };
  const now = moment.tz(timezone);
  const sweepsStartMoment = moment.tz(sweepsStart, timezone);
  const dailyExpires = now.isBefore(sweepsStartMoment)
    ? Object.assign(
        {
          resetDayInWeek: null,
          resetHour: null,
          resetMinute: null,
          resetSecond: null,
        },
        baseExpiresConfig,
        {
          expires: sweepsStartMoment.clone().add(1, 'days'),
        },
      )
    : Object.assign(
        {
          resetDayInWeek: null,
          resetHour: 23,
          resetMinute: 59,
          resetSecond: 59,
        },
        baseExpiresConfig,
      );
  return ['en-GB', 'es-MX'].includes(intl.locale)
    ? [
        {
          src: imgPeriod1,
          alt: '',
          title: <FormattedMessage {...messages.periodDaily} />,
          subtitle: <FormattedMessage {...messages.prize1} />,
          count: loading ? '...' : intl.formatNumber(daily).padStart(2, '0'),
          expires: dailyExpires, // '2019-08-31',
        },
        {
          src: imgPeriod2,
          alt: '',
          title: <FormattedMessage {...messages.periodDaily} />,
          subtitle: <FormattedMessage {...messages.prize2} />,
          count: loading ? '...' : intl.formatNumber(daily).padStart(2, '0'),
          expires: dailyExpires, // '2019-08-31',
        },
      ]
    : [
        {
          src: imgPeriod1,
          alt: '',
          title: <FormattedMessage {...messages.periodDaily} />,
          subtitle: <FormattedMessage {...messages.prize1} />,
          count: loading ? '...' : intl.formatNumber(daily).padStart(2, '0'),
          expires: dailyExpires, // '2019-08-31',
        },
        {
          src: imgPeriod2,
          alt: '',
          title: <FormattedMessage {...messages.periodDaily} />,
          subtitle: <FormattedMessage {...messages.prize2} />,
          count: loading ? '...' : intl.formatNumber(daily).padStart(2, '0'),
          expires: dailyExpires, // '2019-08-31',
        },
        {
          src: intl.locale === 'de-DE' ? imgRanger1 : imgRaptor2,
          alt: '',
          title: <FormattedMessage {...messages.periodGrand} />,
          subtitle: <FormattedMessage {...messages.prize3} />,
          // eslint-disable-next-line no-nested-ternary
          count: loading
            ? '...'
            : intl.locale === 'de-DE'
            ? intl.formatNumber(grandDE).padStart(2, '0')
            : intl.formatNumber(grandUSCA).padStart(2, '0'),
          expires: Object.assign(
            {
              resetDayInWeek: null,
              resetHour: null,
              resetMinute: null,
              resetSecond: null,
            },
            baseExpiresConfig,
          ),
        },
      ];
};
