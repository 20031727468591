import React from 'react';

import SEO from '../components/seo';

import Layout from '../components/Layout/Layout';
import Header from '../components/Header/Header';
import HeroMyStuff from '../components/HeroMyStuff/HeroMyStuff';
import MyStuff from '../components/MyStuff/MyStuff';
import CollectorCans from '../components/CollectorCans/CollectorCans';
import Social from '../components/Social/Social';
import Footer from '../components/Footer/Footer';

const MyStuffPage = ({ location }) => (
  <Layout>
    <SEO title="My Stuff" />
    <Header location={location} />

    <HeroMyStuff name="David" loading={false} upnext="pack-kait" />
    <MyStuff
      daily={1}
      grandUSCA={1}
      grandDE={1}
      redemptions={[
        {
          _id: '5de5f64507c51a00082a534a',
          code: {
            value: 'N2K7T639VXNN',
          },
          packTokens: [
            {
              _id: '5d6b39bf200f9be41c158d02',
              token: 'HCTMM-FXQCP-2C4YK-J49GF-2HW2Z',
              type: 'boost',
            },
          ],
          locale: 'en-US',
          dateRedeemed: '2019-12-03T05:44:37.443Z',
          __v: 1,
          pack: 'pack-boost',
        },
        {
          _id: '5de5f08f3fe03f0008ca8276',
          code: {
            value: 'NKCTHTJ2RV64',
          },
          packTokens: [
            {
              _id: '5d6b39bf200f9be41c158ce5',
              token: 'VWCXT-QPWGX-72R62-C994K-34CYZ',
              type: 'boost',
            },
          ],
          locale: 'en-US',
          dateRedeemed: '2019-12-03T05:20:15.325Z',
          __v: 1,
          pack: 'pack-boost',
        },
        {
          _id: '5de557169471ee00078a7e93',
          code: {
            value: 'NNHPLFJ3LLRP',
          },
          packTokens: [
            {
              _id: '5d6b39bf200f9be41c158ce9',
              token: 'FJWMD-MDRWP-VDP9R-K2JM3-X3X9Z',
              type: 'boost',
            },
          ],
          locale: 'en-US',
          dateRedeemed: '2019-12-02T18:25:26.228Z',
          __v: 1,
          pack: 'pack-boost',
        },
        {
          _id: '5de2b274a31975000843fdbe',
          code: {
            value: 'KT7JKKFLVVHJ',
          },
          packTokens: null,
          locale: 'en-US',
          dateRedeemed: '2019-11-30T18:18:28.905Z',
          __v: 0,
          pack: 'pack-lancers',
        },
        {
          _id: '5de2b06b3887fd00074f22ac',
          code: {
            value: 'PX9N3JC3CJR2',
            chain: 'ampm',
          },
          packTokens: null,
          locale: 'en-US',
          dateRedeemed: '2019-11-30T18:09:47.666Z',
          __v: 0,
          pack: 'pack-scorpion',
        },
        {
          _id: '5de2ae6da31975000843fd88',
          code: {
            value: 'VJ74FJHHRHKV',
            chain: 'ampm',
          },
          packTokens: null,
          locale: 'en-US',
          dateRedeemed: '2019-11-30T18:01:17.027Z',
          __v: 0,
          pack: 'pack-marcus',
        },
        {
          _id: '5db092abac07e40006d605b0',
          code: {
            value: '797PN396839L',
          },
          packTokens: [
            {
              _id: '5d703929200f9be41c0c5e11',
              token: 'FHC9P-7C4CF-TCX42-7T326-7T3KZ',
              type: 'livegold',
            },
            {
              _id: '5d6b3716200f9be41cd1e238',
              token: 'R7737-VGKQ4-FHYV9-FPJX6-39YYZ',
              type: 'jd',
            },
          ],
          locale: 'en-US',
          dateRedeemed: '2019-10-23T17:49:31.373Z',
          __v: 2,
          pack: 'pack-jd',
        },
        {
          _id: '5dafb8ee2bef7b0007d687e4',
          code: {
            value: 'K236XVFL7K68',
          },
          packTokens: [
            {
              _id: '5d6b3596200f9be41cba8f98',
              token: 'PRRDG-PCR33-KDMQ7-XDR3K-64XFZ',
              type: 'kait',
            },
            {
              _id: '5d703929200f9be41c0c5c53',
              token: 'HKCJH-44DHT-WQX2J-JK46D-XXDTZ',
              type: 'livegold',
            },
            {
              _id: '5d6b3d75200f9be41c6be210',
              token: '3Q36G-96QK7-QYYMF-RJCWH-G6PTZ',
              type: 'gamepass',
            },
          ],
          locale: 'en-US',
          dateRedeemed: '2019-10-23T02:20:30.363Z',
          __v: 3,
          pack: 'pack-kait',
        },
      ]}
      isXboxLinked={false}
    />

    <CollectorCans />
    <Social />
    <Footer />
  </Layout>
);

export default MyStuffPage;
