import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';

import MyStuffPrizeStyled from './MyStuffPrize.style';

import CountdownTimer from '../CountdownTimer/CountdownTimer';

const messages = defineMessages({
  entriesReset: {
    id: 'myStuffPrize.entriesReset',
    defaultMessage: 'ENTRIES RESET IN',
  },
});

const MyStuffPrize = ({ data, style = null }) => {
  return (
    <MyStuffPrizeStyled style={style}>
      <div className="MyStuffPrize__title">
        <div className="MyStuffPrize__titleWrap">
          <img className="MyStuffPrize__img" src={data.src} alt={data.alt} />
          <div>
            {data.title}
            <div className="MyStuffPrize__prize">{data.subtitle}</div>
          </div>
        </div>
        <span className="MyStuffPrize__count">{data.count}</span>
      </div>

      <div className="MyStuffPrize__entriesReset">
        <FormattedMessage {...messages.entriesReset} />{' '}
        <span className="MyStuffPrize__timer">
          <CountdownTimer {...data.expires} />
        </span>
      </div>
    </MyStuffPrizeStyled>
  );
};

export default MyStuffPrize;
