import React from 'react';
import PropTypes from 'prop-types';

import HeroMyStuffListStyled from './HeroMyStuffList.style';

const HeroMyStuffList = ({ data }) => {
  const slides = data.slides.map((slide, index) => {
    return (
      <div className="HeroMyStuffList__item" key={index.toString()}>
        <img src={slide.src} alt={slide.alt} />
        <div className="HeroMyStuffList__overlay">
          <div className="HeroMyStuffList__title">{slide.title}</div>
        </div>
      </div>
    );
  });

  return <HeroMyStuffListStyled>{slides}</HeroMyStuffListStyled>;
};

export default HeroMyStuffList;
