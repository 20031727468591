import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

class CountdownTimer extends Component {
  static propTypes = {
    timezone: PropTypes.string,
    overrideNow: PropTypes.string,
    expires: PropTypes.string.isRequired,
    resetDayInWeek: PropTypes.string,
    resetHour: PropTypes.number,
    resetMinute: PropTypes.number,
    resetSecond: PropTypes.number,
  };

  static defaultProps = {
    timezone: 'America/Los_Angeles',
    resetDayInWeek: null,
    resetHour: null,
    resetMinute: null,
    resetSecond: null,
    overrideNow: null,
  };

  constructor(...args) {
    super(...args);
    this.timer = 0;
    const resetTime = this.getResetTime();
    this.state = {
      resetTime,
      time: this.getObjectFromMoment(resetTime),
    };
  }

  componentDidMount() {
    this.startTimer();
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  getObjectFromMoment(resetTime) {
    const { timezone, overrideNow } = this.props;
    const now = overrideNow
      ? moment
          .tz(timezone)
          .subtract(
            moment.tz(timezone).diff(moment.tz(overrideNow, timezone), 'days'),
            'days',
          )
      : moment.tz(timezone);
    const secs = resetTime.diff(now, 'seconds');
    let days = Math.floor(secs / (60 * 60 * 24));
    let hours = Math.floor(secs / (60 * 60));
    let minutes = Math.floor(secs / 60);

    hours %= 24;
    minutes %= 60;
    let seconds = secs % 60;

    seconds = seconds < 10 ? `0${seconds}` : seconds;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    hours = hours < 10 ? `0${hours}` : hours;
    days = days < 10 ? `0${days}` : days;

    const obj = {
      d: days,
      h: hours,
      m: minutes,
      s: seconds,
    };

    return obj;
  }

  getResetTime() {
    const {
      resetDayInWeek,
      resetHour,
      resetMinute,
      resetSecond,
      timezone,
      expires,
      overrideNow,
    } = this.props;
    const now = overrideNow
      ? moment
          .tz(timezone)
          .subtract(
            moment.tz(timezone).diff(moment.tz(overrideNow, timezone), 'days'),
            'days',
          )
      : moment.tz(timezone);
    let resetTime = moment.tz(timezone);
    if (resetHour) {
      resetTime.set('hour', resetHour);
    }
    if (resetMinute) {
      resetTime.set('minute', resetMinute);
    }
    if (resetSecond) {
      resetTime.set('second', resetSecond);
    }
    if (resetDayInWeek) {
      resetTime.isoWeekday(resetDayInWeek);
      if (now.isAfter(resetTime)) {
        resetTime.subtract(1, 'weeks');
      }
    }

    if (!resetHour && !resetMinute && !resetSecond && !resetDayInWeek) {
      resetTime = moment.tz(expires, timezone);
    }

    if (now.isAfter(resetTime)) {
      resetTime.subtract(1, 'days');
    }
    return resetTime;
  }

  startTimer() {
    if (this.timer === 0) {
      this.timer = setInterval(() => this.tick(), 1000);
    }
  }

  tick() {
    const { timezone, expires, overrideNow } = this.props;
    const now = overrideNow
      ? moment
          .tz(timezone)
          .subtract(
            moment.tz(timezone).diff(moment.tz(overrideNow, timezone), 'days'),
            'days',
          )
      : moment.tz(timezone);
    const expiresMoment = moment.tz(expires, timezone);
    let resetTime = this.state.resetTime; // eslint-disable-line
    if (now.isAfter(resetTime)) {
      resetTime = this.getResetTime();
    }
    this.setState({
      resetTime,
      time: this.getObjectFromMoment(resetTime),
    });

    if (now.isAfter(expiresMoment)) {
      clearInterval(this.timer);
      this.setState({
        time: {
          d: '00',
          h: '00',
          m: '00',
          s: '00',
        },
      });
    }
  }

  render() {
    const { time } = this.state;
    return (
      <span>
        d: <strong>{time.d}</strong> h: <strong>{time.h}</strong> m:{' '}
        <strong>{time.m}</strong> s: <strong>{time.s}</strong>
      </span>
    );
  }
}

export default CountdownTimer;
