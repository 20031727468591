import styled from 'styled-components';
import * as defaultTheme from '../variables';
import { fadeInUpBlock } from '../animations';

const MyStuffPrizeStyled = styled.div`
  position: relative;
  min-width: 340px;
  text-transform: uppercase;

  animation-name: ${fadeInUpBlock};
  animation-duration: 500ms;
  animation-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  animation-fill-mode: forwards;
  opacity: 0;
  transform: translate3d(0, 25px, 0);

  @media (${p => p.theme.xsMax}) {
    margin-top: ${p => p.theme.space * 2}px;
  }

  @media (${p => p.theme.smMin}) {
    margin-top: ${p => p.theme.space * 3}px;
    padding-bottom: ${p => p.theme.space * 2}px;
    border-bottom: 1px solid #fff;
  }

  .MyStuffPrize__title {
    display: flex;
    margin-bottom: ${p => p.theme.space}px;
    font-family: 'AzbukaPro';
    font-weight: 900;
    line-height: 1;

    @media (${p => p.theme.xsMax}) {
      align-items: flex-start;
      font-size: 18px;
    }

    @media (${p => p.theme.smMin}) {
      justify-content: space-between;
      align-items: center;
      font-size: 28px;
    }
  }

  .MyStuffPrize__titleWrap {
    display: flex;
  }

  .MyStuffPrize__img {
    margin-right: ${p => p.theme.space * 2}px;
    width: 45px;
    height: 45px;
    object-fit: cover;
    overflow: hidden;
  }

  .MyStuffPrize__prize {
    font-family: 'AzbukaPro';
    font-weight: 500;

    @media (${p => p.theme.xsMax}) {
      display: inline-block;
      margin-left: ${p => p.theme.space}px;
      font-size: 14px;
    }

    @media (${p => p.theme.smMin}) {
      font-size: 18px;
    }
  }

  .MyStuffPrize__count {
    margin-left: ${p => p.theme.space * 2}px;
    padding-left: ${p => p.theme.space * 2}px;
    border-left: 1px solid #fff;

    @media (${p => p.theme.xsMax}) {
      padding-top: 3px;
      padding-bottom: 3px;
      font-size: 14px;
      font-weight: 500;
    }

    @media (${p => p.theme.smMin}) {
      height: 45px;
      line-height: 48px;
    }
  }

  .MyStuffPrize__entriesReset {
    font-size: 12px;

    @media (${p => p.theme.xsMax}) {
      position: absolute;
      bottom: 8px;
      left: 61px;
    }
  }

  .MyStuffPrize__timer {
    white-space: nowrap;
  }
`;

MyStuffPrizeStyled.defaultProps = {
  theme: defaultTheme,
};

export default MyStuffPrizeStyled;
