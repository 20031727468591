import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';
import debounce from 'lodash/debounce';

import HeroMyStuffStyled from './HeroMyStuff.style';
import data from '../Rewards/config';
import CodeForm from '../CodeForm/CodeForm';
import HeroMyStuffSlider from '../HeroMyStuffSlider/HeroMyStuffSlider';
import HeroMyStuffList from '../HeroMyStuffList/HeroMyStuffList';

const messages = defineMessages({
  title: {
    id: 'heroMyStuff.title',
    defaultMessage: 'Welcome,',
  },
  subtitle: {
    id: 'heroMyStuff.subtitle',
    defaultMessage:
      'Track your prize entries and redeem your in-game rewards here.',
  },
  packTitle: {
    id: 'heroMyStuff.packTitle',
    defaultMessage: 'Your next code unlocks:',
  },
});

class HeroMyStuff extends Component {
  state = {
    viewportWidth: 0,
  };

  static propTypes = {
    name: PropTypes.string.isRequired,
    upnext: PropTypes.string,
  };

  static defaultProps = {
    upnext: null,
  };

  componentDidMount() {
    const isClient = typeof window !== 'undefined';

    if (isClient) {
      this.updateWindowDimensions();
      window.addEventListener(
        'resize',
        debounce(this.updateWindowDimensions, 150),
      );
    }
  }

  componentWillUnmount() {
    const isClient = typeof window !== 'undefined';

    if (isClient)
      window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ viewportWidth: window.innerWidth });
  };

  render() {
    const { viewportWidth } = this.state;
    const { name, upnext } = this.props;

    const upnextPack = data.packs.find(pk => pk.id === upnext);
    // if (upnextPack == null) {
    //   upnextPack = data.packs[0];
    // }

    return (
      <HeroMyStuffStyled>
        <div className="HeroMyStuff__inner">
          <div>
            <h1 className="HeroMyStuff__title">
              <FormattedMessage {...messages.title} />
              <p>{name}</p>
            </h1>
            <div className="HeroMyStuff__subtitle">
              <FormattedMessage {...messages.subtitle} />
            </div>

            <CodeForm />
          </div>

          {upnextPack && (
            <div className="HeroMyStuff__packs">
              <div className="HeroMyStuff__packTitle">
                <FormattedMessage {...messages.packTitle} />{' '}
                <strong>{upnextPack.title}</strong>
              </div>

              {upnextPack.slides.length > 5 || viewportWidth < 608 ? (
                <HeroMyStuffSlider data={upnextPack} />
              ) : (
                <HeroMyStuffList data={upnextPack} />
              )}
            </div>
          )}
        </div>
      </HeroMyStuffStyled>
    );
  }
}

export default HeroMyStuff;
