import React from 'react';
import PropTypes from 'prop-types';

import HeroMyStuffSliderStyled from './HeroMyStuffSlider.style';

import Carousel from '../Carousel/Carousel';

const HeroMyStuffSlider = ({ data }) => {
  const slides = data.slides.map((slide, index) => {
    return {
      src: slide.src,
      alt: slide.alt,
      overlay: <div className="HeroMyStuffSlider__title">{slide.title}</div>,
    };
  });

  return (
    <HeroMyStuffSliderStyled>
      <Carousel customWidth={[99]} slides={slides} light />
    </HeroMyStuffSliderStyled>
  );
};

export default HeroMyStuffSlider;
