import styled from 'styled-components';

const HeroMyStuffListStyled = styled.div`
  display: flex;
  overflow: hidden;
  min-height: 130px;

  .HeroMyStuffList__item {
    flex-shrink: 0;
    position: relative;
    width: 99px;

    &:not(:first-child) {
      margin-left: 8px;
    }

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  .HeroMyStuffList__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .HeroMyStuffList__title {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 13px;
    width: 100%;
    height: 28px;
    padding: 0 13px;
    font-family: 'AzbukaPro';
    font-size: 9px;
    font-weight: 500;
    text-align: center;
    line-height: 14px;
    text-transform: uppercase;
  }
`;

export default HeroMyStuffListStyled;
