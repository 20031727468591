import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';
import { FormattedMessage, defineMessages } from 'react-intl';

import MyStuffItemStyled from './MyStuffItem.style';

import Carousel from '../Carousel/Carousel';

const messages = defineMessages({
  btn: {
    id: 'myStuffItem.btn',
    defaultMessage: 'REDEEM',
  },
  // preRewards: {
  //   id: 'myStuffItem.preRewards',
  //   defaultMessage: 'REWARD REDEMPTION BEGINS 9/1',
  // },
  preRewardsModalTitle: {
    id: 'myStuffItem.preRewardsModalTitle',
    defaultMessage: 'AVAILABLE 9/6',
  },
  preRewardsModalDesc: {
    id: 'myStuffItem.preRewardsModalDesc',
    defaultMessage:
      'Come back September 6th to redeem your Xbox Live Gold and Game Pass Rewards.',
  },
  'user-msft-link-already-exists': {
    id: 'myStuffItem.user-msft-link-already-exists',
    defaultMessage: 'Refresh page and please try again.',
  },
  'user-msft-link-already-used': {
    id: 'myStuffItem.user-msft-link-already-used',
    defaultMessage:
      'Xbox Live Account already associated with another user. Logging you out of your Xbox Live session in 5 seconds...',
  },
  'unkown-error': {
    id: 'myStuffItem.unkown-error',
    defaultMessage: 'An unknown error occurred.  Please try again.',
  },
  'network-error': {
    id: 'myStuffItem.network-error',
    defaultMessage:
      'Error communicating with Microsoft API.  Please try again in a little while.',
  },
  'xbox-login-error': {
    id: 'myStuffItem.xbox-login-error',
    defaultMessage: 'Xbox Login Error: {message}',
  },
});

class MyStuffItem extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    style: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    isXboxLinked: PropTypes.bool.isRequired,
    tokenMutation: PropTypes.func.isRequired,
    msftlinkMutation: PropTypes.func.isRequired,
    setModalQueue: PropTypes.func.isRequired,
    clearModalQueue: PropTypes.func.isRequired,
    data: PropTypes.shape({
      redemptionId: PropTypes.string,
      tokenType: PropTypes.string,
      title: PropTypes.string,
      desc: PropTypes.string,
      token: PropTypes.string,
      date: PropTypes.string,
      time: PropTypes.string,
      slides: PropTypes.arrayOf(
        PropTypes.shape({
          src: PropTypes.string,
          alt: PropTypes.string,
          title: PropTypes.string,
        }),
      ),
    }).isRequired,
  };

  render() {
    const { data, style = null } = this.props;
    const slides = data.slides.map(slide => {
      return {
        src: slide.src,
        alt: slide.alt,
        overlay: <div className="MyStuffItem__imgTitle">{slide.title}</div>,
      };
    });

    const now = moment();
    const sept6th = moment.tz('2019-09-06', 'Europe/Berlin');

    const carouselSettings = {
      centerMode: true,
      responsive: [
        {
          breakpoint: 9999,
          settings: {
            dots: false,
            arrows: true,
          },
        },
        {
          breakpoint: 767,
          settings: {
            dots: true,
            arrows: false,
          },
        },
      ],
    };

    return (
      <MyStuffItemStyled style={style}>
        <div className="MyStuffItem__inside">
          <div className="MyStuffItem__date">
            <span>{data.date}</span>
            <span className="MyStuffItem__dateTime">{data.time}</span>
          </div>

          {data.slides.length > 1 && (
            <div className="MyStuffItem__slider">
              <Carousel
                slides={slides}
                settings={carouselSettings}
                customWidth={[130, 170]}
                margin={false}
              />
            </div>
          )}
          {data.slides.length === 1 && (
            <div className="MyStuffItem__img">
              <img src={data.slides[0].src} alt={data.slides[0].alt} />
              <div className="MyStuffItem__imgTitle">
                {data.slides[0].title}
              </div>
            </div>
          )}

          <div className="MyStuffItem__code">
            <div className="MyStuffItem__codeTitle">{data.title}</div>
            <div className="MyStuffItem__codeDesc">{data.desc}</div>

            <div className="MyStuffItem__field">
              {data.token && (
                <input
                  className="MyStuffItem__input"
                  type="text"
                  value={data.token}
                  readOnly
                />
              )}
              <button
                style={{ width: data.token == null ? '125px' : '100px' }}
                className="MyStuffItem__btn"
                type="button"
                onClick={this.onRedeemHandler}
              >
                <FormattedMessage {...messages.btn} />
              </button>
              {data.token == null &&
                ['livegold', 'gamepass'].includes(data.tokenType) &&
                now.isBefore(sept6th) && (
                  <p
                    style={{
                      marginLeft: '10px',
                      marginTop: '5px',
                      color: '#fff200',
                    }}
                  >
                    <FormattedMessage {...messages.preRewardsModalTitle} />
                  </p>
                )}
            </div>
          </div>
        </div>
      </MyStuffItemStyled>
    );
  }
}

export default injectIntl(MyStuffItem);
